import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-library/src/App.css'
import { BrowserRouter, Routes, Route } from "react-router";
import { Home } from './PAGES/Home';
import { Apps } from './PAGES/Apps';
import { AppPolicies } from './PAGES/AppPolicies';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/apps" element={<Apps />} />
      <Route path="/app-policies" element={<AppPolicies />} />
    </Routes>
  </BrowserRouter>
);

import { useNavigate } from 'react-router'
import { Clickable } from './Clickable'
import { RiMenuLine } from 'react-icons/ri'
import { useState } from 'react';
import { MdOutlineArrowOutward } from "react-icons/md";
import { GrClose } from "react-icons/gr";

export function Navigation() {
    const navigate = useNavigate();
    const [showNav, setShowNav] = useState(false);
    return <div className="space">
        <div className='separate-h p-sm vertical-center border-b-1-black'>
            <Clickable onPress={() => { navigate('/') }}><p className='upper line-sm'>Innovative<br />Internet<br />Creations</p></Clickable>
            {
                showNav ? <Clickable onPress={() => {
                    setShowNav(false)
                }}><GrClose className='text-md' /></Clickable> :
                    <Clickable onPress={() => {
                        setShowNav(true)
                    }}><RiMenuLine className='text-md' /></Clickable>
            }
        </div>

        {showNav && <div>
            <Clickable onPress={() => { navigate('/') }}>
                <div className='separate-h p border-b-1-black'>
                    <p className='text-md upper'>Home</p>
                    <MdOutlineArrowOutward className='text-md' />
                </div>
            </Clickable>
            <Clickable onPress={() => { navigate('/projects') }}>
                <div className='separate-h p border-b-1-black'>
                    <p className='text-md upper'>Projects</p>
                    <MdOutlineArrowOutward className='text-md' />
                </div>
            </Clickable>
            <Clickable onPress={() => { navigate('/apps') }}>
                <div className='separate-h p border-b-1-black'>
                    <p className='text-md upper'>Apps</p>
                    <MdOutlineArrowOutward className='text-md' />
                </div>
            </Clickable>
            <Clickable onPress={() => { navigate('/contact') }}>
                <div className='separate-h p border-b-1-black'>
                    <p className='text-md upper'>Contact Us</p>
                    <MdOutlineArrowOutward className='text-md' />
                </div>
            </Clickable>
        </div>}
    </div>
}
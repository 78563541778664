import { Navigation } from "../UTILITIES/Navigation";
import img1 from '../IMAGES/adsmayhem.jpg'
import img2 from '../IMAGES/popmarket.jpg'
import img3 from '../IMAGES/petgrooming.jpg'
import img4 from '../IMAGES/city.jpg'
import { Footer } from "../UTILITIES/Footer";

export function Home() {
    return <div className="space">
        <Navigation />
        <div className="main">
            <div className="row border-b-1-black">
                <div className="p border-black box">
                    <p className="line-sm text-md">We make websites, apps, and solutions designed for the future.</p>
                </div>
                <div className="sm0 md0 lg1 xl1"></div>
                <div className="sm0 md0 lg0 xl1"></div>
                <div className="line-sm p box">
                    <p className='text-right'>Jesus Jimenez Santos
                        <br />
                        CEO, Founder
                        <br />
                        760.208.0335
                        <br />
                        bagel.inbox@gmail.com
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="p box border-b-1-black gap-md flex-column separate-v">
                    <div>
                        <p>.01</p>
                        <h1 className="text-xl upper line-sm">Ads Mayhem</h1>
                        <br />
                        <p className="">An app dedicated for the sole purpose of viewing ads, coupons, and promotions in your area. No bots, no scams. Only real people with genuine interests.</p>
                    </div>
                    <div>
                        <img src={img1} className="full-width full-height cover" />
                    </div>
                </div>
                <div className="p box border-b-1-black gap-md flex-column separate-v">
                    <div>
                        <p>.02</p>
                        <h1 className="text-xl upper line-sm">City Mobile Apps</h1>
                        <br />
                        <p className="">Transforming into a modern city starts with an app that delivers automation and innovative solutions—empowering city councils to elevate urban living.</p>
                    </div>
                    <div>
                        <img src={img4} className="full-width full-height cover" />
                    </div>
                </div>
                <div className="p box border-b-1-black gap-md flex-column separate-v">
                    <div>
                        <p>.03</p>
                        <h1 className="text-xl upper line-sm">Pop! Market</h1>
                        <br />
                        <p className="">An online local marketplace to reserve and pick up anything you want. Perfect for small stores, farmers markets, yard sales, and many more..</p>
                    </div>
                    <div>
                        <img src={img2} className="full-width full-height cover" />
                    </div>
                </div>
                <div className="p box border-b-1-black gap-md flex-column separate-v">
                    <div>
                        <p>.04</p>
                        <h1 className="text-xl upper line-sm">Pet Grooming Edu</h1>
                        <br />
                        <p className="">A dynamic teacher-student portal for lessons, homework, and tests, featuring instant grading and automated feedback to simplify teaching and enhance learning.</p>
                    </div>
                    <div>
                        <img src={img3} className="full-width full-height cover" />
                    </div>
                </div>

            </div>
        </div>
        <Footer />
    </div>
}